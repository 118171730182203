import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'

export default function Version(props) {
    const {version} = props;
    const uiVersion = "2.0.9"
    return <Typography variant="caption">x10trader: {version}, ui: {uiVersion}</Typography>
}

Version.propTypes = {
    version: PropTypes.string.isRequired,
}
